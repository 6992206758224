<template>
  <div class="container">
    <div class="row">
      <div class="home-page flex-center col-12">
        <div class="text-center p-5">
          <h4>Nexi</h4>
          <h1 class="home-page__title">Welcome to the ISO Portal</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './HomePage.scss'

export default {
  name: 'HomePage',
  metaInfo: {
    title: 'ISO Portal',
    titleTemplate: '%s | Nexi',
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
